/* Tooltip.css */
.tooltip-container {
    position: relative;
    display: inline-block;
}

.info-icon {
    cursor: none;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
}

.tooltip-box {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    z-index: 1000;
}
