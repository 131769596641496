@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Sora-Light.ttf');
  }
  @font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/Sora-Medium.ttf');
  }
  @font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/Sora-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Light.ttf');
  }

  /* Scroll bar config */
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #d6d6d6;
    border-radius: 10px;
    border: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(73, 206, 139);
    border-radius: 10px;
    border: 0px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #405e40;
  }
}

/* Tailwind doesn't allow for start/stop percentages on gradients AFAICS */
.bg-gradient-green-blue {
  background: linear-gradient(186.4deg, #4a9ea1 0%, #171753 82%);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-circle {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
